import React from 'react';

function Wave(props) {
    return (
        <div style={{ width: "100%", position: "absolute", top: 0, zIndex: 2 }}>
            <div id="wave_above" />
            <svg id="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                <path fill="#122010" fill-opacity="1" d="M0,192L13.3,202.7C26.7,213,53,235,80,240C106.7,245,133,235,160,202.7C186.7,171,213,117,240,96C266.7,75,293,85,320,112C346.7,139,373,181,400,186.7C426.7,192,453,160,480,128C506.7,96,533,64,560,90.7C586.7,117,613,203,640,224C666.7,245,693,203,720,197.3C746.7,192,773,224,800,250.7C826.7,277,853,299,880,266.7C906.7,235,933,149,960,133.3C986.7,117,1013,171,1040,192C1066.7,213,1093,203,1120,181.3C1146.7,160,1173,128,1200,149.3C1226.7,171,1253,245,1280,240C1306.7,235,1333,149,1360,144C1386.7,139,1413,213,1427,250.7L1440,288L1440,0L1426.7,0C1413.3,0,1387,0,1360,0C1333.3,0,1307,0,1280,0C1253.3,0,1227,0,1200,0C1173.3,0,1147,0,1120,0C1093.3,0,1067,0,1040,0C1013.3,0,987,0,960,0C933.3,0,907,0,880,0C853.3,0,827,0,800,0C773.3,0,747,0,720,0C693.3,0,667,0,640,0C613.3,0,587,0,560,0C533.3,0,507,0,480,0C453.3,0,427,0,400,0C373.3,0,347,0,320,0C293.3,0,267,0,240,0C213.3,0,187,0,160,0C133.3,0,107,0,80,0C53.3,0,27,0,13,0L0,0Z"></path>
            </svg>
        </div>
    );
}

export default Wave;