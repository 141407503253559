import React from 'react';

function SignUp(props) {
    return (
        <div id="signup">
            <div className='button_top'>
                Get yo free stuff
            </div>

            <div className='button'>
                Get yo free stuff
            </div>

            <div className='button_bottom'>
                Get yo free stuff
            </div>
        </div>
    );
}

export default SignUp;